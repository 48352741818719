import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  searchPaginationAtom,
  searchTypeFiltersAtom,
} from "../../../../../state/atoms";
import { gaTrackClickNavAppFilter } from "../../../../../Tracking";
import { FilterDropdownPill } from "./FilterDropdownPill";
import { FilterButtonPill } from "./FilterButtonPill";

const FilterBar: React.FC = () => {
  const [searchTypeFilters, setSearchTypeFilters] = useRecoilState(
    searchTypeFiltersAtom
  );

  const setSearchPagination = useSetRecoilState(searchPaginationAtom);

  const onTypeFilterChange = (
    clickedFilterId: number,
    filterValue: string | null
  ) => {
    setSearchPagination(1);
    const clickedFilter = searchTypeFilters.find(
      (filter) => filter.id === clickedFilterId
    );

    if (clickedFilter && filterValue) {
      gaTrackClickNavAppFilter(filterValue);
      // sendGAConversion("9iyaCLuq5YUYEOfd3oQp");
    }

    setSearchTypeFilters((prev) =>
      prev.map((filter) => {
        const selected =
          filter.type === "button"
            ? !filter.selected
            : filter.type === "dropdown" && filterValue;

        return {
          ...filter,
          selected:
            filter.id === clickedFilterId ? Boolean(selected) : filter.selected,
          selectedValue:
            filter.id === clickedFilterId ? filterValue : filter.selectedValue,
        };
      })
    );
  };

  return (
    <div className="scrollbar-hide flex w-full  justify-between  overflow-x-scroll">
      {" "}
      {searchTypeFilters.map((filter) => {
        if (filter.type === "button") {
          return (
            <FilterButtonPill
              filter={filter}
              key={filter.id}
              onClick={() => onTypeFilterChange(filter.id, filter.value)}
            />
          );
        }

        return (
          <FilterDropdownPill
            filter={filter}
            key={filter.id}
            onSelected={(selectedValue: string | null) =>
              onTypeFilterChange(filter.id, selectedValue)
            }
          />
        );
      })}
    </div>
  );
};

export default FilterBar;
