import { useRecoilState, useRecoilValue } from "recoil";
import { matchFilterAtom } from "../../../../state/atoms";
import { trackDupeListSwitch } from "../../../../Tracking";
import { selectedProductToDupeSelector } from "../../../../state/selectors/selectedProductToDupeSelector";

export const PriceMatchToggle = () => {
  const options = [
    {
      label: "Best Price",
      value: "price",
    },
    {
      label: "Best Match",
      value: "match",
    },
  ];
  const [matchFilter, setMatchFilter] = useRecoilState(matchFilterAtom);
  // const setCurrentlyVisibleMatch = useSetRecoilState(currentlyVisibleMatchAtom);
  const selectedProductToDupe = useRecoilValue(
    selectedProductToDupeSelector(null)
  );

  const onToggle = (value: string) => {
    if (selectedProductToDupe) {
      trackDupeListSwitch(selectedProductToDupe, value);
    }

    setMatchFilter(value);
    document
      .getElementById("match-slider")
      ?.scrollTo({ left: 0, behavior: "smooth" });
  };

  return (
    <div className="flex w-full justify-center">
      <div className="flex  justify-between rounded-full bg-white">
        {options.map((option, index) => (
          <button
            data-cy={`price-match-toggle-${option.value}`}
            key={index}
            onClick={() => onToggle(option.value)}
            className={`rounded-full px-4 py-2 font-circular-medium text-sm ${
              option.value === matchFilter ? " bg-black text-white" : "bg-white"
            }`}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};
