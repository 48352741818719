import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../../../../shared";
import { TSearchDropdownFilter } from "../../../../../types";
import { useTranslation } from "react-i18next";

type TFilterDropdownPillProps = {
  filter: TSearchDropdownFilter;
  onSelected: (selectedValue: string | null) => void;
};

export function FilterDropdownPill({
  filter,
  onSelected,
}: TFilterDropdownPillProps) {
  const selectedChanged = (newValue: string | null) => {
    onSelected(newValue);
  };

  const { t } = useTranslation();

  return (
    <Menu as="div" className=" inline-block text-left">
      <div>
        <Menu.Button
          className={classNames(
            " mr-4 flex h-10 w-40  min-w-fit transform justify-between rounded border border-black py-2  px-4 font-circular-book text-sm text-black transition-transform active:scale-95",
            filter.selected ? "bg-black text-white" : "bg-white text-black "
          )}
        >
          <span className="break-keep  uppercase">
            {filter.selectedValue
              ? filter.options.find(
                  (option) => option.value === filter.selectedValue
                )?.label
              : t(filter.label)}
          </span>
          <ChevronDownIcon
            className=" ml-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active, close }) => {
                useEffect(() => {
                  window.onscroll = () => close();
                }, []);

                return (
                  <button
                    onClick={() => selectedChanged(null)}
                    className={classNames(
                      active || filter.selectedValue === null
                        ? "bg-gray-200"
                        : "",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    ---
                  </button>
                );
              }}
            </Menu.Item>
            {filter.options.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    onClick={() => selectedChanged(option.value)}
                    className={classNames(
                      active || filter.selectedValue === option.value
                        ? "bg-gray-200"
                        : "",
                      "block w-full px-4 py-2 text-left text-sm uppercase"
                    )}
                  >
                    {option.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
