import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/buttons/Button";
import { logoutUser } from "../../../thridparty/firebase/auth/logoutUser";
import { ECookie, classNames, deleteCookie } from "../../../shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { gaTrackClickMenu } from "../../../Tracking";

const menuItems = [
  {
    title: "ABOUT_US_LABEL",
    link: "/de/about-us",
  },
  {
    title: "FAQ_LABEL",
    link: "/de/faq",
  },
  {
    title: "MATCH_SCORE_LABEL",
    link: "/de/faq?jumpTo=howitworks",
  },
  {
    title: "CONTACT_LABEL",
    link: "/de/kontakt",
  },
  {
    title: "ACCOUNT_MANAGE_LABEL",
    link: "/de/account",
  },
];

export const MenuPage = () => {
  const navigate = useNavigate();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const onLogout = async () => {
    try {
      setLogoutLoading(true);
      await logoutUser();
      deleteCookie(ECookie.AccessToken);
      deleteCookie(ECookie.DesiredMatch);
      navigate("/");
    } catch (error) {
      console.log(error);
      alert("Da ist etwas schief gegangen. Bitte versuche es später nochmal!");
    } finally {
      setLogoutLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="flex w-full max-w-xl flex-col bg-dark-purple px-5 pt-4">
        <div className="relative flex h-20 w-full items-center justify-center ">
          <Link to="/" className="absolute left-0  ">
            <ChevronLeftIcon className="h-10 w-10 text-white" />
          </Link>
          <h1 className="  text-center font-ivypresto-regular text-2xl text-white">
            {t("MENUPAGE.TITLE")}
          </h1>
        </div>

        <img
          className={`h-46 w-full  object-contain`}
          src="/v2/images/bg-setting.png"
          alt="logo"
        />
      </div>
      <div className="flex w-full max-w-xl flex-col  px-5 pb-11 pt-4 ">
        {menuItems.map((item, index) => (
          <Link
            onClick={() => gaTrackClickMenu(item.title)}
            data-cy={`menu-item-${index}`}
            key={index}
            className="flex h-12 w-full items-center  justify-between border-b border-gray-300 border-opacity-60 font-circular-book text-base "
            to={item.link}
          >
            {t(`MENU_PAGE.${item.title}`)}
            <ChevronRightIcon className="h-5 w-5" />
          </Link>
        ))}
        <div className="mt-4 font-circular-book text-base">
          <button
            className={classNames(
              i18n.language === "de" ? "font-extrabold underline" : ""
            )}
            onClick={() => i18n.changeLanguage("de")}
          >
            DE
          </button>{" "}
          |{" "}
          <button
            onClick={() => i18n.changeLanguage("en")}
            className={classNames(
              i18n.language === "en" ? "font-extrabold underline" : ""
            )}
          >
            EN
          </button>
        </div>
        <div className="mt-14 flex flex-col items-center justify-center">
          <Button text="Logout" onClick={onLogout} loading={logoutLoading} />

          <a
            href={"https://dupefinder.de/"}
            className="mt-5 font-circular-book underline"
          >
            Matched.com
          </a>
        </div>
      </div>
    </div>
  );
};
