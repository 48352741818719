import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { selectedMatchSelector } from "../../../state/selectors/matchingDupesSelector";
import { Button } from "../../components/buttons/Button";
import { MatchCircle } from "../../components/special/MatchCircle";
import { ProductDetailAccordion } from "./components/ProductDetailAccordion";
import { AffiliateShopsModal } from "../match/components/AffliateShopsModal";
import { TDupeProduct, TProduct } from "../../../types";
import { BottomNavBar } from "../../components/navbar/BottomNavBar/BottomNavBar";
import { ImageCarousell } from "../../../components/carousell/ImageCarousell";
import { useTranslation } from "react-i18next";
import { truncateString } from "../../utils/truncateString";
import { useState } from "react";
import {
  trackFindRetailerButtonClicked,
  trackShopNowButtonClick,
} from "../../../Tracking";
import { useTrackProductView } from "../../../hooks";
import { formatSize } from "../../utils/formatSize";
import { isPriceInRange } from "../../utils/priceInRange";
import { classNames } from "../../../shared";
import { Price, PricePer100ml } from "../../components/text/Price";
import { extractNumberFromString } from "../../utils/extractNumberFromString";

export const ProductDetailPage = () => {
  const { originGtin, matchGtin } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const selectedMatch = useRecoilValue(
    selectedMatchSelector({ originGtin, matchGtin })
  );
  const [shopModalMatch, setShopModalMatch] = useState<TDupeProduct | null>(
    null
  );

  useTrackProductView(selectedMatch, location.pathname);

  const onBuyClicked = (match: TDupeProduct) => {
    if (!match.shops_data || match.shops_data?.length === 0) {
      trackFindRetailerButtonClicked(match?.data);
      window.location.href = `https://www.google.de/search?q=${match.data.namecomplete}`;
      return;
    }
    if (selectedMatch?.data) {
      trackShopNowButtonClick(selectedMatch.data);
    }
    setShopModalMatch(match);
  };

  const productImageUrls: string[] = getProductImageUrls(selectedMatch?.data);

  const onGoBack = () => {
    if (location.state?.from) {
      navigate(location.state?.from, {
        state: { previouslySelectedGtin: matchGtin },
      });
    } else {
      navigate(-1);
    }
  };

  const buttonLabel =
    !selectedMatch?.shops_data || selectedMatch?.shops_data?.length === 0
      ? "SHARED.SEARCH_PRODUCT_BUTTON_LABEL"
      : "SHARED.BUY_PRODUCT_BUTTON_LABEL";

  const matchCircleColor = selectedMatch?.data.colorhex;

  const productName = truncateString(selectedMatch?.data.name, 100);
  const brandName = truncateString(selectedMatch?.data.brand, 15);

  const priceInRange = isPriceInRange(Number(selectedMatch?.data.price_de));

  const price = priceInRange ? (
    <>
      <Price price={selectedMatch?.data.price_de} /> {" | "}
    </>
  ) : (
    t("SHARED.PRICE_NA")
  );

  const hasSavings =
    !Number.isNaN(extractNumberFromString(selectedMatch?.savings.value)) &&
    priceInRange;

  return (
    <div className="relative">
      <div className="flex w-full items-center justify-center">
        <div className="flex min-h-screen w-full max-w-xl flex-col px-5 pb-11 pt-4">
          <div>
            <button onClick={onGoBack} className="font-circular-medium">
              {t("SHARED.BACK_BUTTON_LABEL")}
            </button>
          </div>
          <div className="mt-4  w-full bg-gray-100 py-8 ">
            <ImageCarousell images={productImageUrls} />
          </div>
          <div className="mt-4 flex w-full">
            <div className="w-1/2 ">
              <h2 className=" font-circular-medium text-2xl uppercase">
                {brandName}
              </h2>
              <span className="font-circular-book text-base text-black">
                {productName}
              </span>

              <div className="font-circular-book text-base capitalize text-gray-300">
                {selectedMatch?.data.colormain}
              </div>
            </div>
            <div className="flex w-1/2 flex-col items-end justify-center ">
              <span className=" font-circular-book text-base">
                {price}

                <span className="text-base text-gray-300">
                  {formatSize(selectedMatch?.data.sizeml)}
                </span>
              </span>
              <span className="pb-6 font-circular-book text-xs text-gray-300">
                {priceInRange ? (
                  <PricePer100ml
                    price={selectedMatch?.data.price_de}
                    sizeml={selectedMatch?.data.sizeml}
                  />
                ) : (
                  t("SHARED.PRICE_NA")
                )}{" "}
                / 100<span>&#8202;</span>ml, {t("SHARED.VAT_LABEL")}
              </span>
            </div>
          </div>
          <div className="mt-6">
            <Button
              testId="product-detail-buy-button-first"
              text={t(buttonLabel)}
              onClick={() => onBuyClicked(selectedMatch as TDupeProduct)}
            />
          </div>

          <div>
            <h2 className="mt-7 font-circular-medium text-lg">Matched Score</h2>
            <div className="flex w-full">
              <div className="w-4/6 pr-4 ">
                <div className="mt-2 mb-4 flex w-full items-center justify-between">
                  <div className="min-w-28  mr-4 shrink-0   font-ivypresto-regular">
                    {selectedMatch?.sim_color} % &#8202;
                    <span className="font-circular-book">Color</span>
                  </div>
                  <div className="w-36 ">
                    <MatchBar
                      color={matchCircleColor}
                      percentage={selectedMatch?.sim_color}
                    />
                  </div>
                </div>
                <div className="mt-2 mb-4 flex w-full items-center justify-between ">
                  <span className="min-w-28 mr-4  flex  shrink-0 items-center   text-center font-ivypresto-regular">
                    {selectedMatch?.sim_gloss} % &#8202;
                    <span className="font-circular-book"> Gloss</span>
                  </span>
                  <div className="w-36 ">
                    <MatchBar
                      color={matchCircleColor}
                      percentage={selectedMatch?.sim_gloss}
                    />
                  </div>
                </div>
                <div className="mt-2  mb-2  flex w-full items-center justify-between ">
                  <span className=" min-w-28  mr-4  shrink-0  font-ivypresto-regular">
                    {selectedMatch?.sim_particles} % &#8202;
                    <span className="font-circular-book">Glitter</span>
                  </span>
                  <div className="h-full    w-36">
                    <MatchBar
                      color={matchCircleColor}
                      percentage={selectedMatch?.sim_particles}
                    />
                  </div>
                </div>
              </div>
              <div className="flex h-auto w-2/6 items-center justify-center ">
                {selectedMatch?.sim_total && (
                  <MatchCircle
                    color={matchCircleColor}
                    percentage={parseInt(selectedMatch?.sim_total)}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <span className="w-26 font-ivypresto-regular text-2xl leading-8">
                        {selectedMatch.sim_total}
                        <span>&#8202;</span>%
                      </span>
                      <span className="font-circular-book text-xs ">
                        MATCH*
                      </span>
                    </div>
                  </MatchCircle>
                )}
              </div>
            </div>
          </div>

          <div>
            <ProductDetailAccordion match={selectedMatch} />
          </div>
          <div className="mt-14">
            <Button
              testId="product-detail-buy-button-second"
              text={t(buttonLabel)}
              onClick={() => onBuyClicked(selectedMatch as TDupeProduct)}
            />
          </div>
          <div
            className={classNames(
              "flex ",
              priceInRange && hasSavings ? "justify-between" : "justify-end"
            )}
          >
            {hasSavings && (
              <div className="mt-7 -ml-6 flex h-12 w-36 items-center justify-start rounded-r-full bg-black pl-6">
                {" "}
                <span className=" mb-1 font-ivypresto-half-bold text-white">
                  {selectedMatch?.savings.value}
                </span>
                <span className="ml-2 font-circular-book text-white">
                  {t(selectedMatch?.savings.label || "")}
                </span>
                <span className="ml-0.5 text-base text-white">&sup1;</span>
              </div>
            )}
            <div className="mt-7 flex w-1/2 flex-col items-end justify-end">
              <span className="font-circular-book text-base">
                {price}
                <span className="text-gray-300">
                  {formatSize(selectedMatch?.data.sizeml)}
                </span>
              </span>
              <span className="font-circular-book text-xs text-gray-300">
                {priceInRange ? (
                  <PricePer100ml
                    price={selectedMatch?.data.price_de}
                    sizeml={selectedMatch?.data.sizeml}
                  />
                ) : (
                  t("SHARED.PRICE_NA")
                )}{" "}
                / 100<span>&#8202;</span>ml, {t("SHARED.VAT_LABEL")}
              </span>
            </div>
          </div>
        </div>
        <BottomNavBar />
        <AffiliateShopsModal
          match={shopModalMatch}
          onClose={() => setShopModalMatch(null)}
        />
      </div>
    </div>
  );
};

const MatchBar = ({
  percentage,
  color = "black",
}: {
  percentage: string | undefined;
  color?: string;
}) => {
  return (
    <div className="flex w-full items-center justify-between align-middle">
      <div className="w-full py-2">
        <div className=" h-1.5 w-full rounded  bg-gray-300 bg-opacity-40">
          <div
            className="h-1.5 rounded"
            style={{
              width: `${parseInt(percentage!)}%`,
              backgroundColor: color,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

function getProductImageUrls(product: TProduct | undefined): string[] {
  if (!product) return [];

  return [
    `${import.meta.env.VITE_CDN_URL}/${product.imagefront}`,
    `${import.meta.env.VITE_CDN_URL}/${product.imageside}`,
    `${import.meta.env.VITE_CDN_URL}/${product.imagebrush}`,
  ];
}
