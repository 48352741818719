import { User } from "firebase/auth";

export type TSearchProductsRequestResponse = {
  totalMatches: number;
  products: TProduct[];
};

export type TProduct = {
  id: number;
  fid: string;
  gtin: string;
  name: string;
  type: string;
  brand: string;
  range1: string;
  range2: string;
  sizeml: string;
  category: string;
  colorhex: string;
  price_de: string;
  affiliate: number;
  colormain: string;
  colorname: string;
  imageside: string;
  imagebrush: string;
  imagefront: string;
  imagetop: string;
  imageswatch: string;
  namecomplete: string;
  ingredient_de: string;
  description_de: string;
  imagepackaging: string;
  priceper100ml_de: number;
};

export type TDupeProduct = {
  gtin_originalproduct: string;
  gtin_twinproduct: string;
  id: number;
  shops_data: TShopData[];
  sim_color: string;
  sim_gloss: string;
  sim_particles: string;
  sim_total: string;
  stock: number;
  data: TProduct;
};

export type TShopData = {
  gtin: string;
  id: number;
  price_de: number;
  retailer: string;
  retailer_logo: string;
  url: string;
};

export type TSearchTypeFilter = TSearchButtonFilter | TSearchDropdownFilter;

export type TSearchDropdownFilter = {
  id: number;
  options: TFilterValue[];
  label: string;
  category: string;
  selected: boolean;
  selectedValue: string | null;
  type: "dropdown";
};

export type TSearchButtonFilter = {
  id: number;
  label: string;
  value: string;
  category: string;
  selected: boolean;
  selectedValue: string | null;
  type: "button";
};

export type TFilterValue = {
  label: string;
  value: string;
};

export enum AppLanguages {
  EN = "en",
  DE = "de",
}

export enum EAuthLevel {
  ALWAYS = "ALWAYS", // contents are always visible
  ISUSER = "ISUSER", // only visible to logged in users
  ISPREMIUMUSER = "ISPREMIUMUSER", // only visible to logged in users with premium subscription
  ISADMIN = "ISADMIN", // only visible to Admins
  ISGUEST = "ISGUEST", // only visible to Guests
}

export type TNaviagtionConfigLink = {
  name: string;
  href: string;
  authLevel: EAuthLevel;
};

export type TNavigationConfig = {
  bigNavLinks: TNaviagtionConfigLink[];
  smallNavLinks: TNaviagtionConfigLink[];
};

export type TNewsLetterComponentConfig = {
  title: string;
  description: string;
  legalDisclaimer: string;
  buttonLabel: string;
};

export type TComponentConfig = {
  newsletter: TNewsLetterComponentConfig;
  blurredCarousellCard: TBlurredCarousellCardConfig;
  footer: TFooterConfig;
};

export type TFooterConfig = {
  remarks: string[];
  elements: TFooterElement[];
};

export type TFooterElement = {
  id: string;
  label: string;
  link: string;
};

export type TBlurredCarousellCardConfig = {
  description: string;
  buttonLabel: string;
  benefitList: string[];
};

export type THomePageConfig = {
  title: string;
  buttonLabel: string;
  barcodeScannerButtonLabel: string;
  filterCategories: TFilterCategories[];
};

export type TPaywallConfig = {
  title: string;
  subTitle: string;
  youGetLabel: string;
  acceptTermLabel: string;
  termsLink: {
    link: string;
    label: string;
  };
  userNameInputPlaceHolder: string;
  emailInputPlaceHolder: string;
  passwordInputPlaceHolder: string;
  heroImage: string;
  shownSubscription: {
    id: string;
  };
  loginOrRegisterDescription: string;
  buttonLabel: string;
};

export type TThankYouReason = {
  id: string;
  title: string;
  description: string;
  buttonLabel: string;
  goToDupeLabel: string;
};

export type TRegisterPageConfig = {
  title: string;
  emailLabel: string;
  passwordLabel: string;
  loginInsteadLinkLabel: string;
  loginInsteadLink: string;
  buttonLabel: string;
  orLoginWith: string;
  backButtonLabel: string;
  acceptTermLabel: string;
  pleaseAcceptTermsLabel: string;
  termsLink: {
    link: string;
    label: string;
  };
  sucessFullRegistrationDialog: {
    title: string;
    iconType: string;
    description: string;
    buttonLabel: string;
  };
  registrationFailedDialog: {
    title: string;
    iconType: string;
    description: string;
    buttonLabel: string;
  };
  newsletterLabels: {
    title: string;
    description: string;
    unfoldDescription: string;
  };
  dataPolicyLink: {
    link: string;
    label: string;
  };
};

export type TConfirmEmailAuthLinkPageConfig = {
  title: string;
  sucessDialog: {
    title: string;
    description: string;
    buttonLabel: string;
  };
  errorDialog: {
    title: string;
    description: string;
    buttonLabel: string;
  };
};

export type TForgotPasswordPageConfig = {
  title: string;
  descritpion: string;
  linkLabel: string;
  emailInputLabel: string;
  buttonLabel: string;
  emailSendTitle: string;
  emailSendDescription: string;
  emailSendButtonLabel: string;
};

export type TSetNewPasswordPageConfig = {
  title: string;
  passwordInputLabel: string;
  passwordRepeatInputLabel: string;
};

export type TLoginPageConfig = {
  title: string;
  linkToRegisterLabel: string;
  linkToRegister: string;
  inputLabel: string;
  orLoginWith: string;
  passwordInputLabel: string;
  forgotPasswordLink: string;
  buttonLabel: string;
  errorTitle: string;
  errorDescription: string;
  backButtonLabel: string;
};

export type TTutorialStepConfig = {
  id: number;
  imageUrl: string;
  title: string;
  description: string;
};

export type TTutorialPageConfig = {
  title: string;
  slides: TTutorialStepConfig[];
  buttonLabel: string;
  skipLabel: string;
};

export type TCategoryFilterConfig = {
  id: number;
  category: string;
  value: string;
  label: string;
};

export type TToggleOptionsConfig = {
  label: string;
  value: string;
};

export type TSearchPageConfig = {
  searchBarPlaceholder: string;
  filtersLabel: string;
  deleteFiltersLabel: string;
  productsFoundLabel: string;
  fromCurrencyLabel: string;
  currencyLabel: string;
  categoryFilters: TCategoryFilterConfig[];
  typeFilters: TSearchTypeFilter[];
};

export type TMatchPageConfig = {
  toggleOptions: TToggleOptionsConfig[];
  goToShopButtonLabel: string;
  searchProductButtonLabel: string;
  goToProductDetailsLabel: string;
  cheaperLabel: string;
  moreExpensiveLabel: string;
  shipableLabel: string;
  offersLabel: string;
  linksNotCorrectLabel: string;
  goToShopLabel: string;
  legalDisclaimerLabel: string;
};

export type TPageNotFoundConfig = {
  title: string;
  description: string;
  buttonLabel: string;
};

export type TLogoutPageConfig = {
  logoutStartedLabel: string;
  logoutEndLabel: string;
};

export type TAbonnomentPageConfig = {
  loadingLabel: string;
};

export type TDetailPageConfig = {
  saveLabel: string;
  ingredientsLabel: string;
  buyProductButtonLabel: string;
  searchProductButtonLabel: string;
  colorLabel: string;
  glossLabel: string;
  glitterLabel: string;
  fromLabel: string;
  visualComparison: string;
};

export type TPageConfigs = {
  home: THomePageConfig;
  paywall: TPaywallConfig;
  logout: TLogoutPageConfig;
  detail: TDetailPageConfig;
  abonnement: TAbonnomentPageConfig;
  notFound: TPageNotFoundConfig;
  confirmEmailAuthLink: TConfirmEmailAuthLinkPageConfig;
  register: TRegisterPageConfig;
  forgotPassword: TForgotPasswordPageConfig;
  setNewPassword: TSetNewPasswordPageConfig;
  login: TLoginPageConfig;
  tutorial: TTutorialPageConfig;
  search: TSearchPageConfig;
  matches: TMatchPageConfig;
  info: TInfoPage[];
  accountPage: TAccountPageConfig;
  registerSearch: TRegisterSearchPageConfig;
};

export type TRegisterSearchPageConfig = {
  titleOne: string;
  titleTwo: string;
  titleThree: string;
  showMeLabel: string;
  showMeTheProduct: string;
  searchingLabel: string;
  foundItLabel: string;
  variantOne: {
    titleOne: string;
    titleTwo: string;
    titleThree: string;
    showMeLabel: string;
    showMeTheProduct: string;
    searchingLabel: string;
    foundItLabel: string;
  };
};

export type TAccountPageConfig = {
  createdAtLabel: string;
  nextExtensionLabel: string;
  deleteAccountButtonLabel: string;
  title: string;
  cancelDeleteAccountLabel: string;
  endsAtLabel: string;
  deleteAccountTitle: string;
  buyPremiumButtonLabel: string;
  manageSubscriptionButtonLabel: string;
  deleteFreeAccountDescription: string;
  deleteAccountDescription: string;
  deleteAcccountButtonLabel: string;
};

export type TInfoPageSection = {
  id: string;
  title: string;
  paragraphs: string[];
  tables: TInfoPageTable[];
};

export type TInfoPageTable = {
  title: string;
  headerColumns: string[];
  rows: TInfoPageTableRow[];
};

export type TInfoPageTableRow = {
  [key: string]: string;
};

export type TInfoPage = {
  urlKey: string;
  pageTitle: string;
  sections: TInfoPageSection[];
};

export type TFilterCategories = {
  name: string;
  value: string;
  imageUrl: string;
  labelKey: string;
};

export type TLangConfig = {
  navigation: TNavigationConfig;
  components: TComponentConfig;
  pages: TPageConfigs;
};

export type TRemoteConfig = {
  deConfig: TLangConfig;
  enConfig: TLangConfig;
  searchSuggestions: string[];
};

export type TCardRefs = {
  [key: string]: React.RefObject<HTMLDivElement>;
};

export type TGaEventParameters = {
  [key: string]: string | ((value: any) => void);
};

export enum EIntentType {
  NAILS = "Nails",
  LIPS = "Lips",
  BARCODE = "Barcode",
  INFORMATION = "Information",
}

export enum ELoadedLevel {
  LOADING = 1,
  LOADED = 2,
  ERROR = 3,
  None = 4,
}

export enum EErrorType {
  UserError = "UserError",
  ApiError = "ApiError",
  Exception = "Exception",
  FirebaseError = "FirebaseError",
}

export type TFirebaseError = {
  code: string;
  customData: Record<string, unknown>;
  name: string;
};

export type TError = {
  type: EErrorType;
  message: string;
  userMessage: string;
};

export type TLoggedInUser = {
  status: ELoadedLevel;
  user: IUser | null;
  hasSubscription?: boolean;
};

interface IUser extends User {
  createdAt: string;
}

declare global {
  interface Window {
    gtag: (
      type: string,
      eventName: string,
      parameters: TGaEventParameters
    ) => void;
    REACT_APP_SCANDIT_KEY: string;
    VITE_SCANDIT_KEY: string;
    REACT_APP_TEALIUM_ENVIRONMENT: string; // still set to react app, as this was used before and used on the environments
  }
}

export enum ERoutePaths {
  HOME = "/",
  SEARCH = "/de/suche",
  DETAIL = "/de/detail",
  MATCHES = "/de/matches",
  INFO = "/de/info",
  ACCOUNT = "/de/account",
  REGISTER_SEARCH = "/de/register-search",
  TUTORIAL = "/de/tutorial",
  LOGIN = "/de/login",
  REGISTER = "/de/register",
  FORGOT_PASSWORD = "/de/forgot-password",
  SET_NEW_PASSWORD = "/de/set-new-password",
  CONFIRM_EMAIL_AUTH_LINK = "/de/confirm-email-auth-link",
  THANK_YOU = "/de/thank-you",
  NOT_FOUND = "/de/not-found",
  LOGOUT = "/de/logout",
  ABONNOMENT = "/de/abonnement",
  PICK_SCANNER = "/de/scanner/color-match?mode=choose",
  COLORMATCH_PICK_CATEGORY = "/de/scanner/pick-categroy",
  COLORMATCH = "/de/scanner/color-match",
  COLORMATCH_UPLOAD = "/de/scanner/upload",
  INSTOREMATCH = "/de/scanner/instore-match",
}
