import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { BottomModal } from "../../components/modals/BottomModal";
import { FormProvider, useForm } from "react-hook-form";
import { EmailInputLabled } from "../../components/inputs/EmailInputLabled";
import { LabledInput } from "../../components/inputs/LabledInput";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import { GoogleLogo } from "../../../components";
import { LabeledLineDivider } from "../../components/divider/LabledLineDivider";
import CheckBoxLabelGroup from "../../components/inputs/CheckBoxLabelGroup";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { signUpNewUser } from "../../../thridparty/firebase/auth";
import { subscribeToNewsLetterRequest } from "../../../api";
import { trackUserRegistered } from "../../../Tracking/general/generalTrackingEvents";
import { EErrorType, TError } from "../../../types";
import { convertError } from "../../../shared/utils/convertError/convertError";
import { loginWithGoogle } from "../../../thridparty/firebase/auth/loginWithGoogle";
import { Trans, useTranslation } from "react-i18next";
import { getNewsLetterLabel } from "./utils/getNewsLetterLabel";
import { trackAccountCreateStart } from "../../../Tracking";

type Inputs = {
  email: string;
  password: string;
  terms: boolean;
  newsletter: boolean;
};

export const RegisterModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);
  const methods = useForm<Inputs>();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isOpen) {
      trackAccountCreateStart();
    }
  }, [isOpen]);

  const onSubmit = async ({ email, password, newsletter }: Inputs) => {
    setLoading(true);
    try {
      const userCredentials = await signUpNewUser(email, password);

      if (newsletter) {
        subscribeToNewsLetterRequest(email, userCredentials.user.uid);
      }
      trackUserRegistered(userCredentials.user.uid, "Email");
      navigate("/de/login?modal=thank_you");
    } catch (error) {
      handleLoginError(convertError(error));
    } finally {
      setLoading(false);
    }
  };

  const newsletterChecked = watch("newsletter");

  const newsletterLabel = getNewsLetterLabel(newsletterChecked);

  const handleLoginError = (error: TError) => {
    setError("root.serverError", {
      type: error.type,
      message: error.userMessage,
    });
  };

  const onGoogleSubmit = async () => {
    try {
      const result = await loginWithGoogle();

      if (!result) {
        throw new Error("Google login failed");
      }

      trackUserRegistered(result.uid, "Google");

      navigate("/de/login?modal=thank_you_social");
    } catch (error) {
      handleLoginError(convertError(error));
    }
  };
  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      testId="register-bottom-modal"
    >
      <Dialog.Panel className="w-screen rounded-t-lg bg-black p-5 pt-4 opacity-95 shadow-xl transition-all">
        <div className="absolute right-0 top-7  pr-4 pt-4 sm:block">
          <button
            data-cy="close-button"
            type="button"
            className="rounded-md   text-white hover:text-white "
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mt-6 mb-8 flex w-full items-center justify-center ">
              <h2 className="font-circular-medium text-2xl  text-white">
                {t("REGISTER_PAGE.TITLE")}
              </h2>
            </div>
            <div className="mb-6">
              <EmailInputLabled onClick={() => clearErrors()} />
            </div>
            <div className="mb-5">
              <LabledInput
                labelText={t("REGISTER_PAGE.PASSWORD_LABEL")}
                type="password"
                name="password"
                onClick={() => clearErrors()}
                rules={{ required: true }}
              />
            </div>
            <div className="mb-5">
              <CheckBoxLabelGroup
                label={
                  <Trans
                    i18nKey="REGISTER_PAGE.ACCEPT_TERMS"
                    components={{
                      link1: (
                        <Link
                          className="text-white underline"
                          to={"/de/nutzungsbedingungen"}
                          title="nutzungsbedingungen"
                        >
                          {t("REGISTER_PAGE.TERMS_LINK")}
                        </Link>
                      ),
                      link2: (
                        <Link
                          className="text-white underline"
                          to={"/de/datenschutz"}
                          title="datenschutz"
                        >
                          {t("REGISTER_PAGE.TERMS_LINK")}
                        </Link>
                      ),
                    }}
                  />
                }
                name="terms"
                rules={{ required: true }}
              />
            </div>
            <div className="mb-5">
              <CheckBoxLabelGroup
                label={newsletterLabel}
                name="newsletter"
                rules={{ required: false }}
              />
            </div>

            {/* Display Form or Backend Error */}
            {errors?.root?.serverError.type === EErrorType.FirebaseError ||
            errors?.root?.serverError.type === EErrorType.Exception ? (
              <div className="my-4" data-cy="login-error-message">
                <p className=" font-circular-book text-red-400 ">
                  {errors?.root?.serverError.message}
                </p>
              </div>
            ) : null}

            <div className="mb-5">
              <Button
                testId="register-button"
                type="submit"
                text="Registrieren"
                styleType="secondary"
                loading={isLoading}
              />
            </div>
          </form>
        </FormProvider>
        <div className="mb-5">
          <LabeledLineDivider label="oder" />
        </div>

        <div>
          <button
            onClick={onGoogleSubmit}
            className="mt-2 mb-5 inline-flex w-full cursor-pointer justify-center rounded-full  px-4 text-sm font-medium text-subtle-grey hover:opacity-80"
          >
            <span className="sr-only">Sign in with Google</span>
            <GoogleLogo />
          </button>
        </div>

        <div>
          <span className="font-cirular-book text-sm text-white">
            {t("REGISTER_PAGE.ALREADY_REGISTERED_LABEL_ONE")}{" "}
            <Link
              to={"/de/login?modal=login"}
              className="text-sm font-bold text-white"
            >
              {t("REGISTER_PAGE.ALREADY_REGISTERED_LABEL_TWO")}{" "}
            </Link>
          </span>
        </div>
      </Dialog.Panel>
    </BottomModal>
  );
};
