import { atom } from "recoil";
import { TSearchTypeFilter } from "../../types";

export const searchStringFilterAtom = atom({
  key: "searchStringFilterAtom",
  default: "",
});

export const searchTypeFiltersAtom = atom<TSearchTypeFilter[]>({
  key: "searchTypeFiltersAtom",
  default: [
    // {
    //   id: 6,
    //   category: "Face",
    //   label: "SHARED.TYPE_FILTER_FOUNDATION",
    //   value: "Make-up",
    //   type: "button",
    //   selected: false,
    //   selectedValue: "",
    // },
    {
      id: 1,
      category: "Nail",
      label: "SHARED.TYPE_FILTER_NAILPOLISH",
      value: "Nail Polish",
      type: "button",
      selected: false,
      selectedValue: "",
    },
    {
      id: 2,
      category: "Lip",
      label: "SHARED.TYPE_FILTER_LIPSTICK",
      value: "Lipstick",
      type: "button",
      selected: false,
      selectedValue: "",
    },
    {
      id: 3,
      category: "Lip",
      label: "SHARED.TYPE_FILTER_LIPGLOSS",
      value: "Lipgloss",
      type: "button",
      selected: false,
      selectedValue: "",
    },
    {
      id: 4,
      category: "Lip",
      label: "SHARED.TYPE_FILTER_LIPLINER",
      value: "Lip Pencil",
      type: "button",
      selected: false,
      selectedValue: "",
    },
    {
      id: 5,
      category: "Lip",
      label: "SHARED.TYPE_FILTER_BRAND",
      selected: false,
      selectedValue: "",
      options: [
        {
          label: "Catrice",
          value: "catrice",
        },
        {
          label: "Essence",
          value: "essence",
        },
      ],
      type: "dropdown",
    },
  ],
});

export const categoryFilterAtom = atom({
  key: "categoryFilterAtom",
  default: "",
});

export const searchPaginationAtom = atom<number>({
  key: "searchPaginationAtom",
  default: 1,
});

export const matchFilterAtom = atom({
  key: "matchFilterAtom",
  default: "price",
});
