import { captureException } from "@sentry/react";
import { TSearchProductsRequestResponse } from "../types";
import { getRequest } from "./getRequest";
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from "recoil";

const newBackendUrl = "https://twinfinder.lab.dc.cosnova.com/v2/api";

export const searchProductsRequest = async (
  searchStringFilter: string,
  categoryFilter: string,
  pageSize?: number,
  pageNumber?: number,
  filterTypes?: (string | null)[],
  sortBy?: string,
  sortOrder?: "asc" | "desc"
): Promise<TSearchProductsRequestResponse> => {
  try {
    const searchUrl = createSearchUrl(
      searchStringFilter,
      categoryFilter,
      pageSize,
      pageNumber,
      filterTypes,
      sortBy,
      sortOrder
    );

    const searchProducts = await getRequest(searchUrl);

    return searchProducts;
  } catch (error) {
    console.log(error); // TODO show this to the user
    captureException(error);
    return { totalMatches: 0, products: [] };
  }
};

function createSearchUrl(
  searchStringFilter: string,
  categoryFilter: string,
  pageSize?: number,
  pageNumber?: number,
  filterTypes?: (string | null)[],
  sortBy?: string,
  sortOrder?: "asc" | "desc"
): string {
  const sortByQuery =
    sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : "";

  const searchStringQuery = searchStringFilter
    ? `&searchString=${searchStringFilter}`
    : "";

  const pageSizeQuery = pageSize ? `&pageSize=${pageSize}` : "";

  const pageNumberQuery = pageNumber ? `&page=${pageNumber}` : "";

  // TODO: this was just a quick workaround, I will refactor the filters to be objects later on
  const bestBrandMatch = filterTypes?.find(
    (filter) => filter === "catrice" || filter === "essence"
  );
  filterTypes = filterTypes?.filter(
    (filter) => filter !== "catrice" && filter !== "essence"
  );

  const bestBrandMatchQuery = bestBrandMatch
    ? `&bestBrandMatch=${bestBrandMatch}`
    : "";

  const filterTypesQuery =
    filterTypes && filterTypes.length > 0
      ? `&filterTypes=${filterTypes
          .filter((filter) => filter !== null)
          .join(",")}`
      : "";

  const searchUrl = `${
    import.meta.env.VITE_BACKEND_URL
  }/searchProducts/?category=${categoryFilter}${searchStringQuery}${pageSizeQuery}${pageNumberQuery}${filterTypesQuery}${sortByQuery}${bestBrandMatchQuery}`;

  return searchUrl;
}
