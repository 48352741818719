import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/buttons/Button";
import { AffiliateShopsModal } from "../match/components/AffliateShopsModal";
import { TDupeProduct, TProduct } from "../../../types";
import { BottomNavBar } from "../../components/navbar/BottomNavBar/BottomNavBar";
import { ImageCarousell } from "../../../components/carousell/ImageCarousell";
import { useTranslation } from "react-i18next";
import { truncateString } from "../../utils/truncateString";
import { useState } from "react";
import { selectSingleProductSelector } from "../../../state/selectors/selectSingleProductSelector";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { isPriceInRange } from "../../utils/priceInRange";
import { Price, PricePer100ml } from "../../components/text/Price";
import { formatSize } from "../../utils/formatSize";

export const ProductDetailPageColorMatch = () => {
  const { gtin } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const selectedMatch = useRecoilValue(selectSingleProductSelector(gtin));
  const [shopModalMatch, setShopModalMatch] = useState<TDupeProduct | null>(
    null
  );

  const onBuyClicked = (match: TProduct) => {
    window.location.replace(
      `https://www.google.de/search?q=${match.namecomplete}`
    );
  };

  const productImageUrls: string[] = getProductImageUrls(selectedMatch);

  const onGoBack = () => {
    if (location.state?.from) {
      navigate(location.state?.from, {
        state: { previouslySelectedGtin: gtin },
      });
    } else {
      navigate(-1);
    }
  };

  const buttonLabel = "SHARED.SEARCH_PRODUCT_BUTTON_LABEL";

  const productName = truncateString(selectedMatch?.name, 100);
  const brandName = truncateString(selectedMatch?.brand, 15);

  const priceInRange = isPriceInRange(Number(selectedMatch?.price_de));

  const price = priceInRange ? (
    <>
      <Price price={selectedMatch?.price_de} /> {" | "}
    </>
  ) : (
    t("SHARED.PRICE_NA")
  );

  return (
    <div className="relative">
      <div className="flex w-full items-center justify-center">
        <div className="flex min-h-screen w-full max-w-xl flex-col px-5 pb-11 pt-4">
          <div>
            <button onClick={onGoBack} className="font-circular-medium">
              {t("SHARED.BACK_BUTTON_LABEL")}
            </button>
          </div>
          <div className="mt-4  w-full bg-gray-100 py-8 ">
            <ImageCarousell images={productImageUrls} />
          </div>
          <div className="mt-4 flex w-full">
            <div className="w-1/2 ">
              <h2 className=" font-circular-medium text-2xl">{brandName}</h2>
              <span className="font-circular-book text-base text-gray-300">
                {productName}
              </span>

              <div className="font-circular-book text-base text-gray-300">
                {selectedMatch?.colormain}
              </div>
            </div>
            <div className="flex w-1/2 flex-col items-end justify-center ">
              <span className=" font-circular-book text-base">
                {price}

                <span className="text-base text-gray-300">
                  {formatSize(selectedMatch?.sizeml)}
                </span>
              </span>
              <span className="pb-6 font-circular-book text-xs text-gray-300">
                {priceInRange ? (
                  <PricePer100ml
                    price={selectedMatch?.price_de}
                    sizeml={selectedMatch?.sizeml}
                  />
                ) : (
                  t("SHARED.PRICE_NA")
                )}{" "}
                / 100<span>&#8202;</span>ml, {t("SHARED.VAT_LABEL")}
              </span>
            </div>
          </div>
          <div className="mt-8">
            <Disclosure as="div" className="">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button
                      className={
                        "text-gray-900 flex w-full items-start justify-between border-t  border-gray-200 pt-8 text-left"
                      }
                    >
                      <span className="font-circular-medium text-lg  leading-7">
                        {t("PRODUCT_DETAIL_PAGE.INGREDIENTS")}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <ChevronUpIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronDownIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-4 pr-12 text-left">
                    <p className="text-left font-circular-book text-sm  leading-7 text-gray-300 ">
                      {selectedMatch?.ingredient_de ||
                        t("SHARED.INCIS_NOT_FOUND")}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
          <div className="mt-12">
            <Button
              text={t(buttonLabel)}
              onClick={() => onBuyClicked(selectedMatch as TProduct)}
            />
          </div>
        </div>
        <BottomNavBar />
        <AffiliateShopsModal
          match={shopModalMatch}
          onClose={() => setShopModalMatch(null)}
        />
      </div>
    </div>
  );
};

function getProductImageUrls(product: TProduct | undefined | null): string[] {
  if (!product) return [];

  return [
    `${import.meta.env.VITE_CDN_URL}/${product.imagefront}`,
    `${import.meta.env.VITE_CDN_URL}/${product.imageside}`,
    `${import.meta.env.VITE_CDN_URL}/${product.imagebrush}`,
  ];
}
