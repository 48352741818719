import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ReactNode, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteCookie, ECookie, setCookie } from "../shared";
import { userAtom } from "../state/atoms/userAtom";
import { app } from "../thridparty/firebase/firebaseInit";
import { ELoadedLevel } from "../types";
import { LoadingPage } from "../pages/LoadingPage";
import { captureException } from "@sentry/react";

export const RouteGuard = ({ children }: { children: ReactNode }) => {
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    onAuthStateChanged(getAuth(app), async (_user) => {
      try {
        if (!_user) {
          deleteCookie(ECookie.AccessToken);
          setUser((prev) => ({
            ...prev,
            status: ELoadedLevel.ERROR,
            user: null,
          }));
          return;
        }

        const idToken = await _user.getIdToken(true);

        if (!idToken) {
          console.log("No id Token found");
          return;
        }
        setCookie(ECookie.AccessToken, idToken, 30);
        setUser((prev) => ({
          ...prev,
          status: ELoadedLevel.LOADED,
          user: JSON.parse(JSON.stringify(_user)),
        }));
      } catch (error) {
        captureException(error);
      }
    });
  }, [setUser]);

  return <>{children}</>;
};

export const ProtectedRoute = ({
  redirectPath = "/de/login",
  children,
}: {
  redirectPath?: string;
  children?: React.ReactNode;
}): JSX.Element => {
  const user = useRecoilValue(userAtom);

  if (user?.status === ELoadedLevel.LOADING) {
    return <LoadingPage />;
  }

  if (!user || !user.user) {
    // || !user.user?.emailVerified
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};
